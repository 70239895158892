var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"backups"}},[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('FormBackup',{attrs:{"dialog":_vm.dialog,"form":_vm.form,"persistent":"","max-width":"800px"}}),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.getBackups()}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteBackups(_vm.selectedItemsName)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-menu',{attrs:{"close-on-content-click":false,"max-height":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',{attrs:{"subheader":"","dense":"","flat":""}},[_c('v-list-item-group',{attrs:{"multiple":""},on:{"change":function($event){return _vm.updateRow()}},model:{value:(_vm.settings),callback:function ($$v) {_vm.settings=$$v},expression:"settings"}},[_vm._l((_vm.keysList),function(value,index){return [_c('v-list-item',{key:index,attrs:{"value":value},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary"},on:{"click":toggle}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(value)}},[_vm._v(_vm._s(index))])],1)]}}],null,true)})]})],2)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.backups,"search":_vm.search,"show-select":"","dense":"","show-expand":"","multi-sort":"","loading":_vm.loading,"itemsPerPage":30,"itemKey":"metadata.name"},on:{"input":function($event){return _vm.selectedItems(_vm.selected)}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.headers.length}},[_c('kbd',[_vm._v(_vm._s(item))])])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }